/* xxs */
/* @media (min-width: 375px) {} */

/* xs */
/* @media (min-width: 475px) {} */

/* sm */
/* @media (min-width: 640px) {} */

/* md */
/* @media (min-width: 768px) {} */

/* Lg */
/* @media (min-width: 1024px) {} */

/* xL */
/* @media (min-width: 1280px) {} */

/* 2xL */
/* @media (min-width: 1536px) {} */

/* START BUTTON====================================================*/

.start--btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.start--img {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(112, 112, 112);
  color: var(--white-color);
  width: 10%;
  height: 10%;
  clip-path: inset(0 0 1px 0 round 8px);
  z-index: 5;
  cursor: pointer;
}
.start--img p:hover {
    color: var(--red-color);
    opacity: 1;
}

/* CONTAINER======================================================*/

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

/* SECTION GAP====================================================*/
.section {
    margin-top: 5rem;
}

.loading {
    filter: blur(40px) grayscale(100%);
}
.loaded {
    filter: blur(0) grayscale(0);
    transition: 0.7s ease-in-out;
}
/*================================================================*/

.sharp__red {
    color: var(--red-color);
    font-weight: 200;
}
.sharp__orange {
    color: var(--orange-color);
    font-size: var(--size-sm);
    font-weight: 300;
}
/*=================================================================*/
.model__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--size-base);
    margin-bottom: 0.25em;
    gap: 1rem;
}
.model__descriptions {
    margin: 0;
    padding: 0;
}
.model__details-description {
    font-size: var(--size-base);
}
.model__details-link {
    font-size: var(--size-base);
}
/* MEDIA QUERYY===================================================*/
/* xxs */
@media (min-width: 375px) {
    .container {
        max-width: 375px;
    }
}

/* xs */
@media (min-width: 475px) {
    .container {
        max-width: 475px;
    }
}

/* sm */
@media (min-width: 640px) {
    .container {
        max-width: 640px;
    }
}

/* md */
@media (min-width: 768px) {
    .container {
        max-width: 768px;
    }
}

/* Lg */
@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
    .section {
        margin-top: 10rem;
    }
}

/* xL */
@media (min-width: 1280px) {
    .container {
        max-width: 1280px;
    }
}

/* 2xL */
@media (min-width: 1536px) {
    .container {
        max-width: 1536px;
    }
}