.starting__page {
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    width: var(--size-7xl);
    height: var(--size-7xl);
    z-index: 10000;
    clip-path: inset(0 0 0 0 round 2.5rem);
    border: 1px dotted var(--grey-color);
    border-radius: 8px;
    cursor: pointer;
    color: var(--grey-color);
    transition: clip-path 0.8s ease-in-out;
}

.starting__page:hover {
    clip-path: inset(0 0 0 0 round .5rem);
}

.starting__page-title {
    font-size: var(--size-lg);
    font-weight: 200;
}

.show{
    display: flex;
}
