:root {

  --x: 0;
  --y: 0;

  --black-color: #1a1a1a;
  --black-fnd-color: #0c0c0c;
  --grey-color: #7a7a7a;
  --grey-light-color: #a8a8a8;
  --red-color: #ff0000;
  --orange-color: #ffb95d;
  --orange-dark-color: #f8ab6b;
  --white-color: #f5f3ee;

  /* Sizes */
  --size-xxs: 0.5rem;
  --size-xs: 0.75rem;
  --size-sm: 0.875rem;
  --size-base: 1rem;
  --size-lg: 1.125rem;
  --size-xl: 1.25rem;
  --size-2xl: 1.5rem;
  --size-3xl: 1.875rem;
  --size-4xl: 2.25rem;
  --size-5xl: 3rem;
  --size-6xl: 3.75rem;
  --size-7xl: 4.5rem;
  --size-8xl: 6rem;
  --size-9xl: 8rem;
  --size-10xl: 10rem;
}

* {
  margin: 0;
  line-height: calc(1em + 0.5rem);
}
html {
  text-decoration: none;
  user-select: none;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}
.light-mode {
  --white-color: #1a1a1a;
  --black-color: #f5f3ee;
}
body {
  font-family: 'Epilogue', sans-serif;
  background: var(--white-color);
  color: var(--black-color);
}

/* Hide scrollbar for Chrome, Safari and Opera===================*/
body::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox===================*/
body {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

main {
  display: none;
}
.showMain {
  display: block;
}

img,
picture,
video,
canvas,
svg {
  display: block;
}

/* CSS Variable */
.tracker {
  position: absolute;
  left: 0;
  top: 0;
  color: var(--grey-color);
}

button {
  display: inline-block;
  border: none;
  padding: 0;
  cursor: pointer;
  background: none;
}

/* LOADING BAR=====================================================*/
.loader-ctn {
  display: flex;
  position: absolute;
  margin:0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10005;
}
.loading-bar-number
{
  position: fixed;
  top: 50%;
  
  font-weight: 300;
  font-size: .75rem;
  color: rgb(163, 163, 163);
}
.loading-bar
{
  position: absolute;
  margin:0;
  align-items: center;
  justify-content: center;
  height: 0px;
  width: 100%;
  transform-origin: top left;
  background: var(--black-color);
  transform: scaleX(0);
  transition: transform 0.5s;
  will-change: transform;
}
.hideLoaderCtn 
{
  display: none;
  z-index: -2;
}


/* CANVAS========================================================= */
#c 
{
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.diagram {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}

@media (orientation: landscape) {
  .mobile-nav {
      visibility: hidden;
  }
}