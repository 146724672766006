footer {
    display: none;
}

.footer__title {
    font-size: var(--size-xs);
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.showFooter {
    display: block;
}

/* xxs */
/* @media (min-width: 375px) {} */

/* xs */
/* @media (min-width: 475px) {} */

/* sm */
/* @media (min-width: 640px) {} */

/* md */
/* @media (min-width: 768px) {} */

/* Lg */
/* @media (min-width: 1024px) {} */

/* xL */
/* @media (min-width: 1280px) {} */

/* 2xL */
/* @media (min-width: 1536px) {} */