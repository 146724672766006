.hero {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.hero__inner {
    display: flex;
    width: 100%;
}
.hero__container__left {
    width: 10%;
    
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05),
      5px,
      rgba(255, 255, 255, 0.05) 5px,
      #f5f5f500 5px,
      #f5f5f500 10px
    );
    clip-path: inset(0 0 0.06rem 0 round .55rem);
    
}
.hero__container__middle{
    display: block;
    width: 100%;
}
.hero__container__right {
    width: 10%;
    
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05),
      5px,
      rgba(255, 255, 255, 0.05) 5px,
      #f5f5f500 5px,
      #f5f5f500 10px
    );
    clip-path: inset(0 0 0.06rem 0 round .55rem);
    
}
.hero__img {
    width: 100%;
    height: 75vh;
    background-image: url(/static/img/Propal-2-\ vue\ inter\ B.png);
    background-position: calc(60%);
    background-repeat: no-repeat;
    background-clip: border-box;
    background-size: cover;
    clip-path: inset(0 0 0.06rem 0 round .55rem);
    
}
.hero__ctn {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}
.hero__subtitle {
    font-size: var(--size-xl);
    font-weight: 600;

}
.hero__title {
    font-size: var(--size-lg);
    font-weight: 300;
    color: var(--grey-color);
}
.hero__title__year {
    color: var(--orange-color);
    font-size: var(--size-xs);
    font-weight: 300;
}
.hero__description {
    font-size: var(--size-sm);
    font-weight: 400;
}
.hero__ctn-myname {
    margin-right: auto;
}
.hero__ctn-contact-sep {
    margin: auto;
}
.hero__ctn-contact {
    margin: auto;
}
/* xxs */
@media (min-width: 375px) {
}

/* xs */
@media (min-width: 475px) {
}

/* sm */
/* @media (min-width: 640px) {} */

/* md */
/* @media (min-width: 768px) {} */

/* Lg */
/* @media (min-width: 1024px) {} */

/* xL */
/* @media (min-width: 1280px) {} */

/* 2xL */
/* @media (min-width: 1536px) {} */