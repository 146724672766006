.mobile-nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: var(--white-color);
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    visibility: hidden;
}
.showMobileNav {
    visibility: visible;
}

.mobile-nav__menu {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem; 
}

.mobile-nav__link {
    font-size: var(--size-xl);
    text-decoration: none;
    font-weight: 300;
    color: var(--orange-color);
    letter-spacing: -0.05em;
    transition: color 0.3s;
}

.mobile-nav__link:hover {
    color: var(--orange-color);
}

.mobile-nav__link-line {
    border: 1px solid var(--grey-light-color);
    padding-right: 1.5em;
}

.mobile-nav__sun {
    display: block;
    width: var(--size-xl);
    height: var(--size-xl);
    color: var(--grey-light-color);
}

.mobile-nav__btn {
    font-size: var(--size-sm);
}
.red {
    color: var(--red-color);
}