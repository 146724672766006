.chair3d__inner {
    display: flex;
    width: 100%;
}
.chair3d__container__left {
    width: 10%;
    
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05),
      5px,
      rgba(255, 255, 255, 0.05) 5px,
      #f5f5f500 5px,
      #f5f5f500 10px
    );
    clip-path: inset(0 0 0.06rem 0 round .55rem);
    
}
.chair3d__container__middle{
    display: block;
    width: 100%;
}
.chair3d__container__right {
    width: 10%;
    
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05),
      5px,
      rgba(255, 255, 255, 0.05) 5px,
      #f5f5f500 5px,
      #f5f5f500 10px
    );
    clip-path: inset(0 0 0.06rem 0 round .55rem);
    
}
.chair3d__img {
    position: relative;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    min-width: 100%;
    border-radius: 6px;
    border: 1px dotted var(--grey-light-color);
}

.chair3d__container__bottom {
    display: flex;
    justify-content: space-evenly;
}


.chair3d__container__middle-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    bottom: 50%;
    width: var(--size-7xl);
    height: var(--size-7xl);
    z-index: 10000;
    border: 1px dotted var(--grey-color);
    border-radius: 8px;
    cursor: pointer;
    color: var(--grey-color);
    transition: clip-path 0.8s ease-in-out;
}

.active {
    color: var(--red-color);
    font-weight: 600;
}
.chair3d__container__middle-btn:hover {
    clip-path: inset(0 0 0 0 round .5rem);
}

.chair3d__container__middle-btn-title {
    font-size: var(--size-lg);
    font-weight: 200;
}

.chair3d__hr {
    border-color: var(--grey-light-color);
    margin-top: 1em;
    margin-bottom: 4em;
    opacity: 0.3;
}
.chair3d2__hr {
    border-color: var(--grey-light-color);
    margin-top: 1em;
    margin-bottom: 1em;
    opacity: 0.3;
}

/* xxs */
/* @media (min-width: 375px) {} */

/* xs */
/* @media (min-width: 475px) {} */

/* sm */
/* @media (min-width: 640px) {} */

/* md */
/* @media (min-width: 768px) {} */

/* Lg */
/* @media (min-width: 1024px) {} */

/* xL */
/* @media (min-width: 1280px) {} */

/* 2xL */
/* @media (min-width: 1536px) {} */