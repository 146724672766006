.design2d__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: repeating-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05),
      5px,
      rgba(255, 255, 255, 0.05) 5px,
      #f5f5f500 5px,
      #f5f5f500 10px
    );
    clip-path: inset(0 0 0.06rem 0 round .55rem);
}
.design2d__link{
    width: var(--size-8xl);
    height: var(--size-8xl);
}
.design2d__subtitle {
    font-size: var(--size-xl);
    font-weight: 600;
    color: var(--black-color);
}
.design2d__title {
    font-size: var(--size-lg);
    font-weight: 300;
    color: var(--grey-color);
}
.design2d__description {
    font-size: var(--size-sm);
    color: var(--red-color);
    font-weight: 200;
    max-width: 120ch;
    margin: 1rem 0;
}
.design2d__info-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.design2d__info-container div {
    flex: 1;
    min-width: 250px;
}
.design2d__label {
    font-size: var(--size-sm);
    text-transform: capitalize;
    margin-bottom: 0.25em;
    color: var(--grey-color);
}
.design2d__programs {
    padding: 0;
    list-style: none;
}
.design2d__info {
    font-size: var(--size-sm);
    font-weight: 200;
    text-transform: capitalize;
    color: var(--grey-color);
}

/*==============================*/


.design2d__image-container-info {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.designe2d__image-title {
    font-size: var(--size-base);
    font-weight: 200;
}
.design2d__hr {
    border-color: var(--grey-light-color);
    margin-top: 4em;
    margin-bottom: 4em;
    opacity: 0.3;
}
.design2d__image-container {
    margin-top: 1rem;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
}
/*================*/

.design2d__image-wrapper {
    position: relative;
}
.design2d__image {
    width: 100%;
    height: 100%;
    clip-path: inset(5% 5% 5% 5% round .55rem);
}
.design2d__image-wrapper::after {
    content: '';
    background-color: rgba(145, 145, 145, 0.425);
    clip-path: inset(5% 5% 5% 5% round .55rem);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: background-color 0.3s;
}
.design2d__image-wrapper:hover::after {
    background-color: rgba(145, 145, 145, 0);
}
/*====================*/

.design2d__image-container-alphabet {
    margin-top: 1rem;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    grid-gap: 1rem;
}
.design2d__image-alphabet-wrapper {
    position: relative;
}
.design2d__image-alphabet {
    width: 100%;
    height: 100%;
    clip-path: inset(5% 5% 5% 5% round .55rem);
}
.design2d__image-alphabet-wrapper::after {
    content: '';
    background-color: rgba(145, 145, 145, 0.1);
    clip-path: inset(5% 5% 5% 5% round .55rem);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: background-color 0.3s;
}
.design2d__image-alphabet-wrapper:hover::after {
    background-color: rgba(145, 145, 145, 0);
}
/*================*/




/* xxs */
@media (min-width: 375px) {
    .design2d__link{
        display: inline-block;
        width: var(--size-8xl);
        height: var(--size-8xl);
    }
    .design2d__label {
        font-size: var(--size-sm);
    }
    .design2d__info {
        font-size: var(--size-base);
    }
}

/* xs */
/* @media (min-width: 475px) {} */

/* sm */
/* @media (min-width: 640px) {} */

/* md */
/* @media (min-width: 768px) {} */

/* Lg */
@media (min-width: 1024px) {
    .design2d__subtitle {
        font-size: var(--size-lg);
    }
    .design2d__link{
        display: inline-block;
        width: var(--size-9xl);
        height: var(--size-9xl);
    }
    .design2d__label {
        font-size: var(--size-lg);
    }
    .design2d__info {
        font-size: var(--size-lg);
    }
}

/* xL */
@media (min-width: 1280px) {
    .design2d__subtitle {
        font-size: var(--size-xl);
    }
    .design2d__link{
        display: inline-block;
        width: var(--size-10xl);
        height: var(--size-10xl);
    }
    .design2d__label {
        font-size: var(--size-xl);
    }
    .design2d__info {
        font-size: var(--size-xl);
    }
}

/* 2xL */
/* @media (min-width: 1536px) {} */