.about--content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.about__text--ctn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.about__title {
    font-size: var(--size-lg);
    text-transform: uppercase;
}

.about__description {
    font-size: var(--size-xs);
    font-weight: 300;
    max-width: 60ch;
    margin: 1rem 0;
}
.about__hr {
    border-color: var(--grey-light-color);
    margin-top: 2em;
    margin-bottom: 2em;
    opacity: 0.3;
}
.about__subtitle {
    font-size: var(--size-sm);
    font-weight: 300;
    color: var(--orange-color);
}
.about__technologies-container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin-top: 2em;
}
.about__ul-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2em;
}
.about__ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding-left: 1em;
    gap: 0.8em;
    flex: 1;
}
.about__list {
    font-size: var(--size-xs);
    font-weight: 300;
}



/* xxs */
@media (min-width: 375px) {
    .about__title {
        font-size: var(--size-xl);
    }
    .about__subtitle {
        font-size: var(--size-base);
        font-weight: 300;
        color: var(--orange-color);
    }
}

/* xs */
/* @media (min-width: 475px) {} */

/* sm */
/* @media (min-width: 640px) {} */

/* md */
/* @media (min-width: 768px) {} */

/* Lg */
@media (min-width: 1024px) {
    .about__title {
        font-size: var(--size-2xl);
    }
}

/* xL */
@media (min-width: 1280px) {
    .about__title {
        font-size: var(--size-3xl);
    }
}

/* 2xL */
/* @media (min-width: 1536px) {} */