.header {
    display: none;
    justify-content: flex-end;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
    z-index: 9999;
}

.showHeader {
    display: flex;
}


.header__menu {
    display: none;
}

.header__bars {
    display: block;
    color: var(--orange-color);
    width: var(--size-2xl);
    height: var(--size-2xl);
}

/* xxs */
/* @media (min-width: 375px) {} */

/* xs */
/* @media (min-width: 475px) {} */

/* sm */
/* @media (min-width: 640px) {} */

/* md */
@media (min-width: 768px) 
{
    .header__menu {
        display: flex;
        /* flex-direction: column-reverse; */
        align-items: center;
        list-style: none;
        padding: 0;
        gap: 2em;
    }
    .header__link {
        font-size: var(--size-xs);
        text-decoration: none;
        font-weight: 400;
        color: var(--grey-light-color);
        letter-spacing: -0.05em;
        transition: color 0.25s;
    }
    .header__link-contact {
        font-size: var(--size-xs);
        text-decoration: none;
        font-weight: 400;
        color: var(--red-color);
        letter-spacing: -0.05em;
        transition: color 0.25s;
    }
    .header__link:hover {
        color: var(--red-color);
    }
    .header__line {
        border: 1px solid var(--grey-light-color);
        padding-top: 1.5em;
        opacity: 0.2;
    }
    .header__sun {
        display: block;
        color: var(--grey-light-color);
        width: var(--size-base);
        height: var(--size-base);
    }
    .header__bars {
        display: none;
    }
}

/* Lg */
@media (min-width: 1024px) {
    .header__link {
        font-size: var(--size-sm);
        letter-spacing: -0.05em;
    }
    .header__sun {
        width: var(--size-xl);
        height: var(--size-xl);
    }
}

/* xL */
/* @media (min-width: 1280px) {} */

/* 2xL */
/* @media (min-width: 1536px) {} */
