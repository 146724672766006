.design3d__image-container {
    margin-top: 1rem;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
}

.design3d__image-wrapper {
    position: relative;
}

.design3d__image-wrapper::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.design3d__image {
    width: 100%;
    height: 100%;
}

/* xxs */
/* @media (min-width: 375px) {} */

/* xs */
/* @media (min-width: 475px) {} */

/* sm */
/* @media (min-width: 640px) {} */

/* md */
/* @media (min-width: 768px) {} */

/* Lg */
/* @media (min-width: 1024px) {} */

/* xL */
/* @media (min-width: 1280px) {} */

/* 2xL */
/* @media (min-width: 1536px) {} */